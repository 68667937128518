/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
import _ from 'lodash';
import isArray from 'lodash/isArray';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import youtubeEmbed from 'youtube-embed';
import Plyr from 'plyr-react';
import { MoonLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { SocketContext } from '../../contexts/Socket';
import { startTimer, stopTimer } from '../../Store/question/slice';
import { allowResponseSended, finish, next } from '../../Store/quizzs/slice';
import Button from '../Button/Button';
import Check from '../Check/Check';
import DotCollision from '../DotCollision';
import Input from '../Input/Input';
import StyleWait from '../Wait/styled';
import WordCloud from '../WordCloud';
import StyleQuestion from './styled';
import { colors } from '../../utils/vars';
import { ReactComponent as CheckSVG } from '../../svgs/hand-like.svg';

const Question = ({ sessionType }) => {
  const {
    question, responseSended, roomId, status, questions,
  } = useSelector(
    (state) => state.quizzs,
  );
  const { userId, group } = useSelector((state) => state.users);
  const _question = useSelector((state) => state.question);
  const currentResponses = null;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const socket = useContext(SocketContext);
  const [words, setWords] = useState([]);
  const [theme, setTheme] = useState();
  const [showWordCloud, setShowWordCloud] = useState([]);
  const [nbParticipants, setNbParticipants] = useState();
  const { timeLeft } = useSelector((state) => state.question);
  const themeContext = useContext(ThemeContext);
  const [localStep, setLocalStep] = useState(localStorage.getItem('localStep') ? Number(localStorage.getItem('localStep')) : 0);
  useEffect(() => {
    if (_question?.timeLeft <= 0) {
      dispatch(stopTimer());
    }
  }, [_question?.timeLeft]);
  /**
   * If not has questions, disable this component
   */
  if (!question) {
    return null;
  }

  /**
   * Send user response to API with socket.io
   */
  const onSubmit = async (values) => {
    const { responses } = values;
    const res = responses !== null ? responses : [];
    dispatch(stopTimer());
    dispatch(allowResponseSended(true));
    socket.emit('response', {
      room: roomId,
      userId,
      group,
      questionId: question._id,
      responses: isArray(res) ? res : [res],
      timeLeft,
    });

    if (sessionType === 'free') {
      if (localStep < (questions?.scenario.length - 1)) {
        setLocalStep(localStep + 1);
        localStorage.setItem('localStep', (localStep + 1));
      } else {
        dispatch(finish());
      }
    }
  };

  const goNext = (step) => {
    dispatch(next(step));
    dispatch(allowResponseSended(false));
    dispatch(
      startTimer({
        time: questions?.scenario[step]?.time,
        question: questions?.scenario[step]?.question,
      }),
    );
    document.body.style.zoom = '100%';
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (sessionType === 'free') {
      goNext(localStep);
    }
  }, [localStep]);

  useEffect(() => {
    socket.on('next', ({ step }) => {
      if (sessionType === 'live') {
        goNext(step);
      }
    });

    socket.on('createUser', (data) => {
      setNbParticipants(data?.numberOfParticipants);
    });

    socket.on('showWordCloud', (data) => {
      setWords(data);
      setShowWordCloud(true);
    });
  }, [socket]);

  useEffect(() => {
    setLoading(true);
  }, [responseSended, currentResponses]);
  /**
   * Question type must is checkbox or radio, or throw exception
   */
  if (
    ![
      'multiple',
      'uniq',
      'picture',
      'video',
      'mixedWord',
      'wordCloud',
    ].includes(question.type)
  ) {
    throw new Error(`${question.type} is not good type checkbox or radio`);
  }
  if (!loading) return '';
  if (status === 'wait') {
    return (
      <StyleWait>
        <span>
          Prêt à en découdre ?
        </span>
        <span>
          Le jeu va démarrer dans quelques instants. Tenez vous prêt !
        </span>
        <MoonLoader
          color={themeContext?.primary}
          loading
        />

        {
          nbParticipants > 0 && (<span>{`${nbParticipants} participant${nbParticipants > 1 ? 's' : ''}`}</span>)
        }

        <span>
          Nous attendons encore quelques participants...
        </span>

      </StyleWait>
    );
  }
  return (
    <StyleQuestion onSubmit={handleSubmit(onSubmit)}>
      {
          !responseSended && !_question?.responsesLocked && (<h1>{question.question}</h1>)
      }

      {
        !responseSended && !_question?.responsesLocked && (
        <div className="content-form">
          {
            question?.mediaUrl?.length > 0 ? (question?.mediaUrl?.includes('youtube') ? (
              <iframe
                className="question-video"
                src={youtubeEmbed(question.mediaUrl)}
                title={question.question}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            ) : ((question.mediaUrl?.includes('mp4') ? (
              <Plyr
                source={{
                  type: 'video',
                  sources: [
                    {
                      src: question.mediaUrl,
                    },
                  ],
                }}
                width="100%"
                height="100%"
              />
            ) : (
              <figure className="question-img">
                <img src={question.mediaUrl} alt="" />
              </figure>
            )))) : null
          }

          {['mixedWord', 'wordCloud'].includes(question.type) ? (
            <Input
              register={register}
              defaultValue={currentResponses?.indexes[0]}
              disabled={responseSended}
            />
          ) : (
            <div className="check-wrapper">
              {
                   question.responses.map(({ _id, label }) => (
                     <Check
                       id={_id}
                       key={_id}
                       label={label}
                       defaultChecked={_.includes(currentResponses?.indexes, _id)}
                       register={register('responses')}
                       type={question.type === 'uniq' ? 'radio' : 'checkbox'}
                       disabled={responseSended}
                     />
                   ))
               }
            </div>
          )}
        </div>

        )
      }
      {!responseSended && !_question?.responsesLocked ? (
        <Button type="submit">Valider</Button>
      ) : (
        <div className="wait-for-next-wrapper">
          <span>Votre réponse a été bien prise en compte </span>
          <CheckSVG />
          <span>Prochaine question dans quelques instants ...</span>
        </div>
      )}

      {showWordCloud && words.length > 0 ? <WordCloud words={words} /> : null}
    </StyleQuestion>
  );
};

Question.propTypes = {
  sessionType: PropTypes.string.isRequired,
};

export default Question;
