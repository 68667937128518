import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import ConfettiExplosion from 'react-confetti-explosion';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import {
  StyleFinish, StylePersonalClassment,
} from './styled';
import { colors } from '../../utils/vars';
import CircularProgress from '../CircularProgress/CircularProgress';
import ProgressProvider from '../ProgressProvider/ProgressProvider';
import Gum from '../Gum/Gum';

const confettiInitConfig = {
  force: 0.8,
  duration: 6000,
  particleCount: 300,
  floorHeight: 1600,
  floorWidth: 1600,
};
const Finish = ({ sessionType }) => {
  const { classmentByGroup, classmentByUser } = useSelector((state) => state.quizzs);
  const {
    group, userId,
  } = useSelector((state) => state.users);
  const themeContext = useContext(ThemeContext);
  const [personalPosition, setPersonalPosition] = useState();
  const [personalScore, setPersonalScore] = useState();
  const [groupPosition, setGroupPosition] = useState();
  const [groupScore, setGroupScore] = useState();
  const [confettiConfig, setConfettiConfig] = useState();

  useEffect(() => {
    if (themeContext?.secondary) {
      setTimeout(() => {
        setConfettiConfig({
          ...confettiInitConfig,
          colors: [themeContext.primary, themeContext.secondary, colors.emperor],
        });
      }, 2000);
    }
  }, [themeContext]);

  useEffect(() => {
    if (sessionType === 'live' && classmentByGroup && classmentByUser) {
      const tmpPersonalPosition = classmentByUser?.findIndex((user) => user._id === userId);
      const tmpPersonalScore = classmentByUser[tmpPersonalPosition]?.average ?? 0;
      const tmpGroupPosition = classmentByGroup?.findIndex((_group) => _group.group === group);
      const tmpGroupScore = classmentByGroup[tmpGroupPosition]?.average ?? 0;
      setPersonalPosition(tmpPersonalPosition);
      setPersonalScore(tmpPersonalScore);
      setGroupPosition(tmpGroupPosition);
      setGroupScore(tmpGroupScore);
    }
  }, [classmentByGroup, classmentByUser]);

  const getPositionSup = (index) => {
    switch (index) {
      case 0:
        return 'er';
      default:
        return 'ème';
    }
  };

  return (
    <StyleFinish>
      {(groupPosition < 3 && sessionType === 'live') && confettiConfig && (
      <ConfettiExplosion {..._.omit(confettiConfig, [])} />
      )}

      {
        sessionType === 'free' && (
        <div className="thanks-free-wrapper">
          <span>Merci de votre participation</span>
          <span>les gagnants seront connus prochainement.</span>
        </div>
        )
      }

      {/* <div className="podium"> */}
      {/*  <div className="winner second"> */}
      {/*    <StyleName>{classmentByGroup[1]?.group}</StyleName> */}
      {/*    <div className="chair"><span>2</span></div> */}
      {/*  </div> */}
      {/*  <div className="winner first"> */}
      {/*    <StyleName> */}
      {/*      {classmentByGroup[0]?.group} */}
      {/*      <Star /> */}
      {/*    </StyleName> */}
      {/*    <div className="chair"><span>1</span></div> */}
      {/*  </div> */}
      {/*  <div className="winner third"> */}
      {/*    <StyleName>{classmentByGroup[2]?.group}</StyleName> */}
      {/*    <div className="chair"><span>3</span></div> */}
      {/*  </div> */}
      {/* </div> */}

      {
        sessionType === 'live' && classmentByGroup && (
        <>
          <div className="header">

            <Gum top="calc( 50% - 70px )" right="calc( 50% - 160px )" shadow="4px -3px 3px" />
            <Gum top="calc( 50% - 70px )" left="calc( 50% - 160px )" borderRadius="10px 75px 47px 90px" />
            <Gum bottom="calc( 50% - 120px )" left="calc( 50% - 160px )" borderRadius="70px 10px 52px 28px" />
            <Gum bottom="calc( 50% - 120px )" right="calc( 50% - 160px )" borderRadius="40px 75px 47px 90px" shadow="4px -3px 3px" />
            <h2>Résultat de votre équipe</h2>
            <ProgressProvider valueStart={0} valueEnd={parseFloat(groupScore).toFixed(2)}>
              {(value) => <CircularProgress percentage={value} />}
            </ProgressProvider>
          </div>

          <div className="body">

            <div className={`body_titles ${classmentByGroup[0]?.group && group === classmentByGroup[0].group ? 'winner' : ''}`}>
              {
                ((parseInt(groupPosition, 10) + 1) > 0) ? (
                  <span>
                    Votre équipe est
                    {' '}
                    <strong>
                      {parseInt(groupPosition, 10) + 1}
                      {' '}
                      <sup>{getPositionSup(groupPosition)}</sup>
                    </strong>
                    {' '}
                    avec
                    {' '}
                    <strong>
                      {parseFloat(groupScore).toFixed(2)}
                      {' '}
                      pts
                    </strong>
                  </span>
                ) : (
                  <p>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    Votre équipe n'a répondu à aucune question,
                    donc vous ne faites pas partie du classement :(
                  </p>
                )
              }
            </div>
            <div className="body_details">
              <div className="item title">
                <span>Classement</span>
                <span>sur 100 points</span>
              </div>
              {
                classmentByGroup?.map((item, index) => (
                  <div
                    id={item?.id}
                    className={`item  
                    ${item.group === group ? 'winner' : ''} 
                    ${item.group === group && group === classmentByGroup[0]?.group ? 'first' : ''}`}
                  >
                    <span>{ `${index + 1}.  ${item.group}`}</span>
                    <span>{`${parseFloat(item.average).toFixed(2)} points`}</span>
                  </div>
                ))
              }

            </div>

          </div>
          <StylePersonalClassment>
            {/* <div className="meta"> */}
            {/*  <p>Votre score est :</p> */}
            {/*  <p><strong>{Math.ceil(personalScore)}</strong></p> */}
            {/* </div> */}
            {/* <div className="meta"> */}
            {/*  <p>Vous êtes :</p> */}
            {/*  <p> */}
            {/*    <strong> */}
            {/*      {parseInt(personalPosition, 10) + 1} */}
            {/*      <sup>{getPositionSup(personalPosition)}</sup> */}
            {/*    </strong> */}
            {/*  </p> */}
            {/* </div> */}

            {/* <div className={`meta ${group === classmentByGroup[0].group ? 'winner' : ''}`}> */}

            {/* </div> */}
            {/* <div className="meta"> */}
            {/*  <p> */}
            {/*    Vous êtes */}
            {/*    {' '} */}
            {/*    <strong> */}
            {/*      {`${parseInt(personalPosition, 10) + 1}`} */}
            {/*      <sup>{getPositionSup(personalPosition)}</sup> */}
            {/*    </strong> */}
            {/*    {' '} */}
            {/*    avec */}
            {/*    {' '} */}
            {/*    <strong> */}
            {/*      {Math.ceil(personalScore)} */}
            {/*      pts */}
            {/*    </strong> */}
            {/*  </p> */}
            {/* </div> */}
          </StylePersonalClassment>
          {/* <StyledFinishTitle> */}
          {/*  <span>Classement :</span> */}
          {/* </StyledFinishTitle> */}
          {/* <StyleGlobalClassment> */}
          {/*  { */}
          {/*          classmentByGroup?.map((item, index) => ( */}
          {/*            <div
          id={item?.id}
          className={`item  ${item.group === group ? 'winner' : ''}
          ${item.group === group && group === classmentByGroup[0]?.group ? 'first' : ''}`}
          > */}
          {/*              <span>{ `${index + 1}.  ${item.group}`}</span> */}
          {/*              <span>{`${Math.ceil(item.average)} points`}</span> */}
          {/*            </div> */}
          {/*          )) */}
          {/*        } */}
          {/* </StyleGlobalClassment> */}
        </>
        )
      }

    </StyleFinish>
  );
};

Finish.propTypes = {
  sessionType: PropTypes.string.isRequired,
};
export default Finish;
